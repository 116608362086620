import { Route } from "react-router";
import Login from "./Login";
import Logout from "./Logout";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import ForgotPassword from "./ForgotPassword";

function AuthorizationRoutes() {
  return (
    <>
      <Route path={ApplicationPaths.Login} render={() => <Login></Login>} />
      <Route path={ApplicationPaths.LogOut} render={() => <Logout></Logout>} />
      <Route path={ApplicationPaths.ForgotPassword} render={() => <ForgotPassword></ForgotPassword>} />
    </>
  );
}

export default AuthorizationRoutes;
