import { Component } from 'react'
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import FulfilmentTabs from "./Tabs";
import FulfilmentGrid from "./FulfillmentGrid";
import { ApiResponse, FileResponse, FilteredReportRequestDTO, FormSubmissionDTO, KitShipmentStatusClient, KitsOrderedRequestDTO, ShipmentStatus, ShipOrdersDTO, SubmissionSource } from "api/clients";
import FulfilmentFilters from "./Filters";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import DownloadFile from "api/downloadFile";

interface OrdersArchivedProps {}

interface OrdersArchivedState {
  data: FilteredReportRequestDTO
  isSearching: boolean
  gridData: FormSubmissionDTO[] | null
  editAddressOpen: boolean
  street: string
  streetNumber: string
  unitNumber: string
  city: string
  country: string
  user: string
  kitID: number
  selectedOrders: ShipOrdersDTO[]
}

export default class OrdersArchived extends Component<OrdersArchivedProps, OrdersArchivedState> {
    constructor(props: OrdersArchivedProps) {
        super(props);
        this.state = {
          data: new FilteredReportRequestDTO(),
          isSearching: false,
          gridData: null,
          editAddressOpen: false,
          street: "",
          streetNumber: "",
          unitNumber: "",
          city: "",
          country: "",
          user: "",
          kitID: 0,
          selectedOrders: []
        };
      }
      openModal = () => this.setState({ editAddressOpen: true });
      closeModal = () => this.setState({ editAddressOpen: false }); 
    ExportGirdToCSV = () => {
        var orderReq = new KitsOrderedRequestDTO();
        orderReq.status = [ShipmentStatus.Archived]
        orderReq.filters = this.state.data;
        (new KitShipmentStatusClient()).getKitsOrderedCsv(orderReq).then((resp: FileResponse | null) => {
          DownloadFile(resp);
        }).catch((err: any) => {
          toast.error("Error getting report, please try again.");
          console.error('Error getting report:', err)
        });
      }

    GetOrders = (filters: FilteredReportRequestDTO) =>{
        var orderReq = new KitsOrderedRequestDTO();
        orderReq.status = [ShipmentStatus.Archived]
        orderReq.filters = filters;
        this.setState({ data: filters});
        (new KitShipmentStatusClient()).getKitsOrdered(orderReq)
        .then((resp: any | null) => {
            this.setState({gridData: resp})
        }).catch((err: any) => {
            toast.error("Error getting report, please try again.");
            console.log('Error getting report:' + err)
        })
      } 

      ToggleSelected = (row: any, RefreshGrid:any ) => {
        if(this.state.selectedOrders.find(x => x.orderID === row.id)){
          this.setState({selectedOrders: this.state.selectedOrders.filter(x => x.orderID !== row.id)})
        }
        else{
          var order = new ShipOrdersDTO();
          order.orderID = row.id;
          order.submissionSource = SubmissionSource[row.submissionSource.replace(/\s/g,'') as any] as unknown as SubmissionSource
          this.setState({selectedOrders: this.state.selectedOrders.concat(order)})
        }
        RefreshGrid()
      }

      EditInventory = () => {
        if(this.state.selectedOrders.length > 0){
          new KitShipmentStatusClient().shipOrders(this.state.selectedOrders).then((response: ApiResponse) => {
            if(response.responseCode === 200){
              toast.success("Orders marked as shipped");
              this.GetOrders(new FilteredReportRequestDTO(this.state.data));
              this.setState({selectedOrders: []})
            }
            else{
              toast.error("Error marking orders as shipped");
            }
          }).catch((err: any) => {
            toast.error("Error updating inventory, please try again.");
          })
        }else{
          toast.error("No orders selected");
        }
      }

      SelectAllRowsButton = (RefreshGrid:any) => {
        if (this.state.selectedOrders.length === this.state.gridData?.length){
          this.setState({selectedOrders: []})
        }
        else{
          this.setState({selectedOrders: this.state.gridData?.map(x => new ShipOrdersDTO({ orderID: x.id, 
            submissionSource: x.submissionSource !== undefined ? SubmissionSource[x.submissionSource.replace(/\s/g,'') as any] as unknown as SubmissionSource : SubmissionSource.Unknown,
           }) ) ?? []})
        }
    
        RefreshGrid();
      }
    
      CheckIfToggled=(id: number) => {
        return this.state.selectedOrders.find(x => x.orderID === id) !== undefined
      }

      EditAddressGridButton = (userID: string, id: number) => {
        this.setState({user: userID, kitID: id})
        this.openModal()
      }
      
      EditAddress = () => {
        new KitShipmentStatusClient().updateOrderAddress(this.state.kitID, this.state.street, this.state.streetNumber, this.state.unitNumber, this.state.city)
        .then((response: ApiResponse) => {
          if(response.responseCode === 200){
            this.GetOrders(new FilteredReportRequestDTO())
            this.closeModal()
            toast.success("Address Updated");
            this.setState({street: "", streetNumber: "", unitNumber: "", city: "", country: "", user: "", kitID: 0})
          }
          else{
            toast.error("Error updating address, please try again.");
          }
        }).catch((err: any) => {
          toast.error("Error updating address, please try again.");
          console.error('Error updating address:', err)
        })
      }
    render() {
        return (
            <WrappedPage breadcrumbs={[{ label: "Order Archived" }]}>
                <FulfilmentTabs></FulfilmentTabs>
                <CCard>
                    <CCardBody className="c-body-full">
                        <FulfilmentFilters handleSubmit={this.GetOrders} initialFilters={this.state.data} isSearching={this.state.isSearching}></FulfilmentFilters>
                        <div><hr></hr></div>
                        <FulfilmentGrid ToggleSelected={this.ToggleSelected} SelectAllRowsButton={this.SelectAllRowsButton} CheckIfToggled={this.CheckIfToggled} orders={this.state.gridData} ExportGirdToCSV = {this.ExportGirdToCSV} isEditable={true} EditAddressGridButton={this.EditAddressGridButton}/>
                        {
                            this.state.gridData !== null ? (<div className="text-right mt-2">
                            <button type="submit" className="btn btn-primary ml-2" onClick={this.EditInventory }><i className="cil-check-alt btn-icon mr-2"></i> Mark As Shipped</button>
                            </div>) : (null)
                        }
                    </CCardBody>
                </CCard>
                <Modal show={this.state.editAddressOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Address for User: {this.state.user}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="form-group">
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>Street: </label>
              <input type="street" 
                    className="form-control"
                    style={{width: "75%", float: "left"}}
                    id="inputStreet" 
                    placeholder="Street"
                    value={this.state.street}
                    onChange={(e) => this.setState({street: e.target.value})}>
              </input>
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>Street Number: </label>
              <input type="number" 
                    className="form-control" 
                    style={{width: "75%", float: "left"}}
                    id="inputStreetNumber" 
                    placeholder="Street Number"
                    value={this.state.streetNumber}
                    onChange={(e) => this.setState({streetNumber: e.target.value})}>
              </input>
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>Unit Number: </label>
              <input type="number" 
                    className="form-control"
                    style={{width: "75%", float: "left"}} 
                    id="inputUnitNumber" 
                    placeholder="Unit Number"
                    value={this.state.unitNumber}
                    onChange={(e) => this.setState({unitNumber: e.target.value})}>
              </input>
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>City: </label>
              <input type="city" 
                    className="form-control" 
                    style={{width: "75%", float: "left"}} 
                    id="inputCity" 
                    placeholder="City"
                    value={this.state.city}
                    onChange={(e) => this.setState({city: e.target.value})}>
              </input>
            </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-primary" onClick={this.closeModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={this.EditAddress}>Submit</button>
            </Modal.Footer>
          </Modal>
            </WrappedPage>
        )
    }
}

