import {
  ApplicationUserDTO,
  ILookupsDTO
} from "../../api/clients";

export interface LookupsState {
  isFetching: boolean;
  data?: ILookupsDTO;
  lastChecked?: Date;
}

export const SET_LOOKUPS = "SET_LOOKUPS";
export const SET_FETCHING_LOOKUPS = "SET_FETCHING_LOOKUPS";
export const SET_USER = "SET_USER";

interface SetLookupsAction {
  type: typeof SET_LOOKUPS;
  lookups: LookupsState;
}

interface SetFetchingLookupsAction {
  type: typeof SET_FETCHING_LOOKUPS;
  isFetching: boolean;
}

interface SetUserAction {
  type: typeof SET_USER;
  user: ApplicationUserDTO;
}

export type LookupsActionTypes =
  | SetLookupsAction
  | SetFetchingLookupsAction
  | SetUserAction;
