import { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from "./Layout";
import AuthorizeRoute from "components/authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "components/authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "components/authorization/ApiAuthorizationConstants";

import AppRoutes from "components/app/Routes";
import { LookupsState } from "store/lookups/types";
import { connect } from "react-redux";
import { AppState } from "store";
import * as LookupActions from "store/lookups/actions";

type AppProps = LookupsState & {
  fetchLookups: any;
};

const App = ({ fetchLookups, data, lastChecked, isFetching }: AppProps) => {
  const [authState, setAuthState] = useState<any>({
    ready: false,
    authenticated: false
  })

  useEffect(() => {
    // If the user isn't populated
    if (lastChecked === undefined && !isFetching) {
      fetchLookups();
    }

    if (lastChecked !== undefined) {
      setAuthState({
        ready: true,
        authenticated: data?.user !== undefined
      })
    }
  }, [data, lastChecked, isFetching, fetchLookups]);

    return (
      <>
        <BrowserRouter>
          <Layout {...authState}>
            <Switch>
              {AppRoutes.map(x =>
                x.exact ? (
                  <AuthorizeRoute
                    key={x.path}
                    exact
                    path={x.path}
                    component={x.component}
                    {...authState}
                  />
                ) : (
                  <AuthorizeRoute
                    key={x.path}
                    path={x.path}
                    component={x.component}
                    {...authState}
                  />
                )
              )}
            </Switch>
            <Route
              path={ApplicationPaths.ApiAuthorizationPrefix}
              component={ApiAuthorizationRoutes}
            />
          </Layout>
        </BrowserRouter>
      </>
    );
}

export default connect(
  (state: AppState) => ({ ...state.lookups }),
  LookupActions.actionCreators
)(App);