import { useCallback, useEffect, useState } from "react";
import { ApplicationPaths, QueryParameterNames } from "./ApiAuthorizationConstants";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { ManagedForm, ValidationOutput } from "components/common/Form";
import { get } from "lodash";
import { CButton, CSpinner } from "@coreui/react";
import {
  ApiException,
  HttpStatusCode,
  LoginRequest
} from "api/clients";
import { AppState } from "store";
import { connect } from "react-redux";
import * as LayoutActions from "store/layout/actions";
import * as LookupsActions from "store/lookups/actions";
import { LookupsState } from "store/lookups/types";
import AuthorizeService from "./AuthorizeService";
import AuthContainer from "./AuthContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type LoginProps = LookupsState & {
  fetchLookups: () => void;
};

function Login({ data, fetchLookups }: LoginProps) {
  const navigation = useHistory();
  const methods = useForm();
  const [loggingIn, setLoggingIn] = useState(false);
  const [authErrors, setAuthErrors] = useState('');

  const getReturnUrl = useCallback(() => {
    const params = new URLSearchParams(navigation.location.search);

    return params.get(QueryParameterNames.ReturnUrl) || "/";
  }, [navigation]);

  const onSubmit = async (data: any) => {
    if (loggingIn) {
      return;
    }

    setLoggingIn(true);

    AuthorizeService.signIn(LoginRequest.fromJS(data))
      .then(() => {
        fetchLookups();
      })
      .catch((reason: ApiException) => {
        if (reason.status === HttpStatusCode.Unauthorized) {
          setAuthErrors("Couldn't Login: Invalid Credentials");
        } else {
          setAuthErrors(reason.message);
        }

        setLoggingIn(false);
      });
  };

  useEffect(() => {
    if (data?.user !== undefined) {
      // If you're already logged in redirect to the dashboard
      navigation.push(getReturnUrl());
    }
  }, [data, getReturnUrl, navigation]);

  return <AuthContainer>
    <div className="login-container">
      <div className="text-danger auth-errors mb-2">{authErrors}</div>
      <ManagedForm onSubmit={onSubmit} methods={methods}>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon icon="user"></FontAwesomeIcon>
              </span>
              <label className="sr-only" htmlFor="email">
                Email
              </label>
            </div>
            <input
              disabled={loggingIn}
              type="text"
              id="email"
              placeholder="Email"
              className={`form-control ${
                get(methods?.formState?.errors ?? {}, "email")
                  ? "border-danger"
                  : ""
              }`}
              {...methods.register("email", { required: true })}
            />
          </div>
          <ValidationOutput name="email" />
        </div>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon icon="lock"></FontAwesomeIcon>
              </span>
              <label className="sr-only" htmlFor="password">
                Password
              </label>
            </div>
            <input
              disabled={loggingIn}
              type="password"
              id="password"
              placeholder="Password"
              className={`form-control ${
                get(methods?.formState?.errors ?? {}, "password")
                  ? "border-danger"
                  : ""
              }`}
              {...methods.register("password", { required: true })}
            />
          </div>
          <ValidationOutput name="password" />
        </div>
        <div className="text-right">
          <CButton color="primary" type="submit" disabled={loggingIn}>
            {loggingIn ? (
              <>
                <CSpinner size="sm" className="mr-2" /> Logging In...
              </>
            ) : (
              "Log in"
            )}
          </CButton>
        </div>
      </ManagedForm>
    </div>
  </AuthContainer>
}

export default connect((state: AppState) => ({ ...state.lookups }), {
  ...LayoutActions.actionCreators,
  ...LookupsActions.actionCreators
})(Login);
