import { Component } from 'react'
import { toast } from "react-toastify";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import ReportingTabs from "./Tabs"

import { FormSubmissionClient, FilteredReportRequestDTO, FileResponse } from "api/clients";
import DownloadFile from "api/downloadFile";
import ReportFilters from "./Filters"

interface KitsOrderedProps {}

interface KitsOrderedState {
  data: FilteredReportRequestDTO
  isSearching: boolean
}

export default class KitsOrderedReport extends Component<KitsOrderedProps, KitsOrderedState> {

  constructor(props: KitsOrderedProps) {
    super(props);
    this.state = {
      data: new FilteredReportRequestDTO(),
      isSearching: false
    };
  }

  handleSubmit = (filters: FilteredReportRequestDTO) => {

    this.setState({ isSearching: true });

    (new FormSubmissionClient()).getKitsOrderedCsv(filters)
      .then((resp: FileResponse | null) => {
        DownloadFile(resp);

        this.setState({ 
          isSearching: false
        })
      }).catch((err: any) => {
        toast.error("Error getting report, please try again.");
        console.error('Error getting report:', err)
      });
  };

  render() {
    let { data, isSearching } = this.state

    return (
      <>
        <ReportingTabs></ReportingTabs>
        <WrappedPage breadcrumbs={[{ label: "Reporting" }]}>
          <CCard>
            <CCardBody>
              <ReportFilters handleSubmit={this.handleSubmit} initialFilters={data} isSearching={isSearching}></ReportFilters>
            </CCardBody>
          </CCard>
        </WrappedPage>
      </>
    )
  }
}
