import {
  LocationDTO
} from "api/clients";
import cities from "common/cities"
import { Form, WrappedInput, Checkbox, WrappedSelect } from "components/common/Form";

type EditLocationFormProps = {
  location: LocationDTO;
  onSubmit: any;
};

export default function LocationEditForm({ onSubmit, location }: EditLocationFormProps) {

  return (
    <div className="mb-3">
      <Form defaultValues={location} onSubmit={onSubmit} autoComplete="off">
        <div className="row">
          <div className="col-12 col-lg-6">
            <WrappedInput name="name" label="Name" rules={{required: true }} />
            <WrappedInput name="key" label="Key" rules={{required: true }} />
          </div>
          <div className="col-12 col-lg-6">
            <WrappedSelect options={cities} name="cityCode" label="City" rules={{ required: true }} />
            <Checkbox name="enabled" label="Enabled" checked={location.enabled} id="IsEnabled" />
            <Checkbox name="isPickup" label="Pickup Location" checked={location.isPickup} id="IsPickup" />
            {/*Hide this as requested in task {35683979} <Checkbox name="isCommunityLink" label="Community Link" checked={location.isCommunityLink} id="IsCommunityLink" />*/}
          </div>
        </div>

        <div className="text-right">
          <input className="btn btn-primary" type="submit" />
        </div>
      </Form>
    </div>
  );
}
