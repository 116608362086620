import { useState } from "react";
import { useForm } from "react-hook-form";
import { ManagedForm, ValidationOutput } from "components/common/Form";
import { get } from "lodash";
import { CButton, CSpinner } from "@coreui/react";
import {
  ApiException
} from "api/clients";
import { AppState } from "store";
import { connect } from "react-redux";
import * as LayoutActions from "store/layout/actions";
import * as LookupsActions from "store/lookups/actions";
import { LookupsState } from "store/lookups/types";
import AuthorizeService from "./AuthorizeService";
import AuthContainer from "./AuthContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValidEmail } from "common/validation";
import { toast } from "react-toastify";

type ForgotPasswordProps = LookupsState & {
};

function ForgotPassword({ data }: ForgotPasswordProps) {
  const methods = useForm();
  const [processing, setProcessing] = useState(false);

  const onSubmit = async (data: any) => {
    if (processing) {
      return;
    }

    setProcessing(true);

    AuthorizeService.sendLostPassword(data.email!)
      .then(() => {
        toast.success("Forgotten password email has been sent.");
      })
      .catch((reason: ApiException) => {
          toast.error("Couldn't send lost password email. Something went wrong.");
      }).finally(() => {
        setProcessing(false);
      });
  };

  return <AuthContainer>
    <div className="forgot-password-container">
      <ManagedForm onSubmit={onSubmit} methods={methods}>
        <h1>Forgot your password?</h1>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FontAwesomeIcon icon="user"></FontAwesomeIcon>
            </span>
            <label className="sr-only" htmlFor="email">
              Email
            </label>
          </div>
          <input
            disabled={processing}
            type="text"
            id="email"
            placeholder="Email"
            className={`form-control ${
              get(methods?.formState?.errors ?? {}, "email")
                ? "border-danger"
                : ""
            }`}
            {...methods.register("email", { 
              required: true,
              validate: (email: string) => !isValidEmail(email) ? "Must be a valid email" : true 
            })}
          />
          <div className="input-group-append">
            <CButton color="accent" type="submit" disabled={processing}>
              {processing ? (
                <>
                  <CSpinner size="sm" className="mr-2" /> Processing...
                </>
              ) : (
                "Submit"
              )}
            </CButton>
          </div>
        </div>
        <ValidationOutput name="email" />

      </ManagedForm>
    </div>
  </AuthContainer>
}

export default connect((state: AppState) => ({ ...state.lookups }), {
  ...LayoutActions.actionCreators,
  ...LookupsActions.actionCreators
})(ForgotPassword);
