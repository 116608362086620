export const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const toJsRegex = (regex: string): string =>
  regex
    .replace("\\A", "^")
    .replace("\\Z", "$")
    .replace("\\z", "$")
    .replace(/^\\b/, "^")
    .replace(/\\b$/, "$");
