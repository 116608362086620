import * as React from "react";
import NavMenuLink from "./SideBarMenuLink";
import MenuLink from "types/MenuLink"

const MenuLinks: MenuLink[] = [
  MenuLink.fromJS({ id: 'Reporting', label: "Reporting", icon: 'cil-chart-line', url: "/reporting/kits-ordered" }),
  MenuLink.fromJS({ id: 'Fulfillment', label: "Fulfillment", icon: 'cil-check-alt', url: "/fulfillment/fulfill-delivery-kits" }),
  MenuLink.fromJS({ id: 'KitTypes', url: "/kit-types", label: "Kit Types", icon: 'cil-border-all' }),
  MenuLink.fromJS({ id: 'Locations', url: "/locations", label: "Locations", icon: 'cil-location-pin' }),
  MenuLink.fromJS({ id: 'Inventory', label: "Inventory", icon: 'cil-clipboard', children: [
    MenuLink.fromJS({ id: 'CurrentInventory', url: "/inventory", label: "Current Inventory" }),
    MenuLink.fromJS({ id: 'ExportTransactions', url: "/inventory/export-transactions", label: "Export Transactions", icon: 'cil-history' }),
    MenuLink.fromJS({ id: 'ReceiveShipment', url: "/inventory/receive-shipment", label: "Receive Shipment", icon: 'cil-plus' }),
    MenuLink.fromJS({ id: 'DisposeInventory', url: "/inventory/dispose", label: "Dispose Inventory", icon: 'cil-minus' })
  ] }),  
  MenuLink.fromJS({ id: 'Surveys', url: "/surveys", label: "Surveys", icon: 'cil-spreadsheet' })
]

export default class NavMenu extends React.PureComponent<{}, {}> {
  public render() {

    return (
      <>
        {MenuLinks.map(x => 
          <NavMenuLink key={x.label} link={x} level={0}></NavMenuLink>
        )}
      </>
    );
  }
}
