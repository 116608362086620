import { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import ReportingTabs from "./Tabs"
import { ApiException, ApiResponse, FileResponse, FilteredReportRequestDTO, HttpResponseType, KitTypeDTO, KitTypesClient, LocationDTO, LocationsClient, ReportingClient } from "api/clients";
import DownloadFile from "api/downloadFile";
import ReportFilters from "./Filters"
import { SelectOption, SelectOptionGroup } from 'common/selectOption';
import { getReasonPhrase } from 'http-status-codes';
import Loading from 'components/common/Loading';
import ApiResponseAlert from 'components/common/ApiResponseAlert';
import cities from "common/cities"

interface KitsOrderedProps {}

export default function PickupsClaimedVsDeliveriesReport(props: KitsOrderedProps) {

  const now = new Date();

  const [reportingClient] = useState(new ReportingClient())
  const [locationClient] = useState(new LocationsClient())
  const [kitTypeClient] = useState(new KitTypesClient())
  const [ready, setReady] = useState(false)
  const [searching, setSearching] = useState(false)
  const [filters] = useState(FilteredReportRequestDTO.fromJS({
    startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6).toISOString(),
    endDate: now.toISOString()
  }))
  const [kitTypes, setKitTypes] = useState<SelectOption[]>([])
  const [locations, setLocations] = useState<SelectOptionGroup[]>([])
  const [actionResult, setActionResult] = useState<ApiResponse | undefined>(undefined);

  useEffect(() => {
    async function getData() {
      setReady(false)
      
      let locationsPromise = locationClient
        .getAll()
        .then((result: ApiResponse) => {
          var locations = result.data! as LocationDTO[]

          setLocations(cities.map(x => {
            return {
              label: x.label,
              value: x.value,
              options: locations.filter(y => y.enabled && x.value === y.cityCode).map(y => {
                return { value: y.id, label: y.name }
              })
            }
          }))
        })
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting locations: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      let kitTypesPromise = kitTypeClient
        .getAll()
        .then((result: ApiResponse) => {
          setKitTypes((result.data! as KitTypeDTO[]).filter(x => x.enabled).map(x => { return { label: x.name, value: x.id } }))
        })
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting kit types: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      await Promise.all([ locationsPromise, kitTypesPromise ])

      setReady(true);
    }

    getData();
  }, [locationClient, kitTypeClient]);

  let handleSubmit = (filters: FilteredReportRequestDTO) => {

    setSearching(true)

    reportingClient.getPickUpVsDeliveryReportCsv(filters)
      .then((resp: FileResponse | null) => {
        DownloadFile(resp);

        setSearching(false)
      }).catch((err: any) => {
        toast.error("Error getting report, please try again.");
        console.error('Error getting report:', err)
      });
  };

  return (
    <>
      <ReportingTabs></ReportingTabs>
      <WrappedPage breadcrumbs={[{ label: "Reporting" }]}>
      {!ready && <Loading color="primary" label="Loading Requested vs Claimed Form"></Loading>}
      {ready &&
        <CCard>
          <CCardBody>
          {actionResult !== undefined && (
            <ApiResponseAlert data={actionResult}></ApiResponseAlert>
          )}
            <ReportFilters handleSubmit={handleSubmit} kitTypes={kitTypes} locations={locations} initialFilters={filters} isSearching={searching}></ReportFilters>
          </CCardBody>
        </CCard>
      }
      </WrappedPage>
    </>
  )
}
