import { useState } from 'react'

import { SurveySubmissionFilteredReportRequestDTO } from "api/clients";
import { SurveryReportFilterSubmission } from "common/types"
import { default as ReactSelect, SingleValue } from "react-select";
import { SelectOption } from 'common/selectOption';
import { DateTime} from "luxon"

interface SurveyFiltersProps {
  handleSubmit: SurveryReportFilterSubmission
  isSearching: boolean
  initialFilters: SurveySubmissionFilteredReportRequestDTO
  surveys?: SelectOption[]
}

export default function SurveyFilters({ handleSubmit, isSearching, initialFilters, surveys }: SurveyFiltersProps) {

  const [filters, setFilters] = useState<SurveySubmissionFilteredReportRequestDTO>(initialFilters);

  let resetForm = () => {
    setFilters(SurveySubmissionFilteredReportRequestDTO.fromJS({
      startDate: initialFilters.startDate,
      endDate: initialFilters.endDate,
      surveyID: surveys !== undefined ? surveys[0].value : 0
    }))
  }

  let handleInputChange = (event: any) => {
    const target = event.target;

    var date = target.value.length > 0 ? DateTime.fromISO(target.value) : undefined

    setFilters(SurveySubmissionFilteredReportRequestDTO.fromJS({
      ...filters, 
      [target.name]: date
    }))
  }

  let handleSelectChange = (key: string, value: any) => {
    setFilters(SurveySubmissionFilteredReportRequestDTO.fromJS({
      ...filters,
      [key]: value
    }))

  }

  let submitForm = (event: any) => {
    event.preventDefault();

    if(!validateForm()) {
      return;
    }

    handleSubmit(filters!);

    return false;
  }

  let validateForm = (): boolean => {

    return true; // startDate !== undefined && endDate !== undefined
  };

  return (
    <>
    {filters !== undefined && 
    <form onSubmit={submitForm}>
      <div className="row">
        <div className="col col-md-6">
          <div className="form-group form-row row">
            <label className="col-form-label col-4" htmlFor="date-from">Date from:</label>
            <div className="col-8">
              <input 
              id="date-from" 
              className="form-control" 
              name="startDate"
              value={filters!.startDate !== undefined ? filters!.startDate!.toISODate() : ""} 
              type="date" 
              onChange={(event) => handleInputChange(event)} />
            </div>
          </div>
        </div>
        <div className="col col-md-6">
          <div className="form-group form-row row">
            <label className="col-form-label col-4" htmlFor="date-to">Date to:</label>
            <div className="col-8">
              <input 
              id="date-to" 
              className="form-control" 
              name="endDate"
              value={filters!.endDate !== undefined ? filters!.endDate!.toISODate() : ""}
              type="date" 
              onChange={(event) => handleInputChange(event)} />
            </div>
          </div>
        </div>

        {surveys !== undefined && 
        <div className="col col-md-6">
          <div className="form-group form-row row">
            <label className="col-form-label col-4 required" htmlFor="locationID">Survey:</label>
            <div className="col-8">
              <ReactSelect
              isMulti={false}
              isClearable={false}
              value={filters!.surveyID > 0 ? surveys.find(x => x.value === filters!.surveyID) : surveys[0]}
              id="surveyID" 
              name="surveyID"
              options={surveys!}
              onChange={(newValue: SingleValue<SelectOption>) => handleSelectChange("surveyID", newValue?.value)} />
            </div>
          </div>
        </div>
        }

      </div>

      <div className="text-right">
        <button type="button" className="btn btn-link" onClick={(e) => resetForm()}>Reset</button>
        <button type="submit" className="btn btn-primary ml-2" disabled={isSearching}>Submit</button>
      </div>
    </form>
  }
  </>
  )
}
