import React from 'react'
import { render } from 'react-dom'
import { Provider } from "react-redux";

import App from './components/app/App'
import { AppStore } from "./store";
import * as serviceWorker from './serviceWorker'
import './assets/scss/custom.scss'
import "react-datepicker/dist/react-datepicker.css";

import "../node_modules/@fortawesome/fontawesome-free/js/all";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';


const Root = () => (
    <Provider store={AppStore}>
        <App />
    </Provider>
)

render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
