import { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import { ApiException, ApiResponse, FileResponse, SurveySubmissionFilteredReportRequestDTO, HttpResponseType, SurveyClient, SurveyDTO } from "api/clients";
import DownloadFile from "api/downloadFile";
import SurveyFilters from "./SurveyFilters"
import { SelectOption } from 'common/selectOption';
import { getReasonPhrase } from 'http-status-codes';
import Loading from 'components/common/Loading';
import ApiResponseAlert from 'components/common/ApiResponseAlert';

interface SurveysProps {}

export default function Surveys(props: SurveysProps) {
    const now = new Date();

    const [surveyClient] = useState(new SurveyClient())

    const [ready, setReady] = useState(false)
    const [filters] = useState(SurveySubmissionFilteredReportRequestDTO.fromJS({
        startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6).toISOString(),
        endDate: now.toISOString()
      }))
    const [searching, setSearching] = useState(false)

    const [surveys, setSurveys] = useState<SelectOption[]>([])
    const [actionResult, setActionResult] = useState<ApiResponse | undefined>(undefined);

    let handleSubmit = (filters: SurveySubmissionFilteredReportRequestDTO) => {

      if (filters.surveyID <= 0) {
        toast.warning("You must choose a survey");
        return;
      }

      setSearching(true)
  
      surveyClient.getSurveySubmissionReportCsv(filters)
        .then((resp: FileResponse | null) => {
          DownloadFile(resp);
  
          setSearching(false)
        }).catch((err: any) => {
          toast.error("Error getting report, please try again.");
          console.error('Error getting report:', err)
        });
    };

    useEffect(() => {
        async function getData() {
            setReady(false)
        
            let surveysPromise = surveyClient
            .getSurveys()
            .then((result: ApiResponse) => {
                var existingSurveys = result.data! as SurveyDTO[]
    
                setSurveys(existingSurveys.map(x => {
                  return {
                      label: x.description,
                      value: x.id
                  }
                }))
                filters.surveyID = existingSurveys && existingSurveys[0] ? existingSurveys[0].id : 0;
            })
            .catch((result: ApiException) => {
                setActionResult(
                ApiResponse.fromJS({
                    responseType: HttpResponseType.Error,
                    messages: [
                    "Error getting surveys: " + getReasonPhrase(result.status)
                    ]
                })
                );
            });
    
            await Promise.all([ surveysPromise ])
    
            setReady(true);
        }
        getData();
    }, 
    // eslint-disable-next-line
    []);
    return (
        <>
          <WrappedPage breadcrumbs={[{ label: "Surveys" }]}>
          {!ready && <Loading color="primary" label="Loading Surveys"></Loading>}
          {ready &&
            <CCard>
              <CCardBody>
              {actionResult !== undefined && (
                <ApiResponseAlert data={actionResult}></ApiResponseAlert>
              )}
              {filters.surveyID > 0 && (
                <SurveyFilters handleSubmit={handleSubmit} surveys={surveys} initialFilters={filters} isSearching={searching}></SurveyFilters>
              )}
              </CCardBody>
            </CCard>
          }
          </WrappedPage>
        </>
      )
}