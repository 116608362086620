import {
  AccessTokenResponse,
  ApplicationUserDTO,
  AuthClient,
  Client,
  FileResponse,
  ForgotPasswordRequest,
  LoginRequest
} from "api/clients";
import { AppStore } from "store";

export class AuthorizeService {
  isAuthenticated() {
    return !!this.getUser();
  }

  getUser(): ApplicationUserDTO | undefined {
    return AppStore.getState().lookups?.data?.user;
  }

  async signIn(data: LoginRequest): Promise<AccessTokenResponse> {
    return new Client().postLogin(data, true, true);
  }

  async signOut(): Promise<FileResponse | null> {
    return new AuthClient().logout();
  }

  async sendLostPassword(email: string): Promise<void> {
    return new Client().postForgotPassword(ForgotPasswordRequest.fromJS({
      email
    }));
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export enum AuthenticationResultStatus {
  Redirect = "redirect",
  Success = "success",
  Fail = "fail"
}
