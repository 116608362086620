import { useCallback, useEffect, useState } from "react";
import {
  LocationDTO,
  LocationsClient,
  ApiException,
  ApiResponse,
  HttpResponseType,
  ToggleEnabledDTO,
  HttpStatusCode
} from "api/clients";
import { getReasonPhrase } from "http-status-codes";
import Loading from "components/common/Loading";
import { Link } from "react-router-dom";
import ApiResponseAlert from "components/common/ApiResponseAlert";
import ToggleEnabledDialog from "components/common/ToggleEnabledDialog";
import SCardHeader from "components/common/CardHeader";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import SGrid from "components/common/SGrid";

type ManageLocationsProps = {};

const EditLocationRenderer = (props: ICellRendererParams) => {
  return <div className="ag-cell-h-full d-flex align-items-center justify-content-end">
    <div className="btn-group btn-group-sm">
      <Link
        to={"/locations/edit/" + props.data.id}
        className="btn btn-outline-primary"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </div>
  </div>
}

export default function ManageLocations (props: ManageLocationsProps) {

  const [ready, setReady] = useState(false)
  const [locations, setLocations] = useState<LocationDTO[] | undefined>(undefined)
  const [locationsClient] = useState(new LocationsClient())
  const [actionResult, setActionResult] = useState<ApiResponse | undefined>(undefined)
  const [actionButtons] = useState([
    {
      to: "/locations/add",
      icon: "fas fa-plus-circle",
      label: "Create"
    }
  ])
  const [showUpdateEnabled, setShowUpdateEnabled] = useState(false)
  const [updateLocation, setUpdateLocation] = useState<LocationDTO | undefined>(undefined)
  const [updateLocationError, setUpdateLocationError] = useState<ApiResponse | undefined>(undefined)

  const getLocations = useCallback((): void => {
    setReady(false)

    locationsClient
      .getAll()
      .then((response: ApiResponse) => {
        setLocations(response.data as LocationDTO[])
        setReady(true)
      })
      .catch((reason: ApiException) => {
        setActionResult(ApiResponse.fromJS({
          responseType: HttpResponseType.Error,
          messages: [
            "Error trying to list locations: " + getReasonPhrase(reason.status)
          ]
        }))
        setReady(true)
      });
  }, [locationsClient])

  /* const deleteLocation = (id: number): void => {
    locationsClient
      .delete(id)
      .then((resp: ApiResponse) => {
        setActionResult(ApiResponse.fromJS({
          messages: ["Location Deleted"],
          responseType: HttpResponseType.Success
        }))

        getLocations()
      })
      .catch((exception: ApiException) => {
        setActionResult(ApiResponse.fromJS({
          responseType: HttpResponseType.Error,
          messages: [
            `Error deleting role ${id}: ${getReasonPhrase(exception.status)}`
          ]
        }))
      })
  } */

  const toggleLocationEnabled = (location?: LocationDTO): void => {
    if(!showUpdateEnabled) {
      setShowUpdateEnabled(true)
      setUpdateLocation(location!)
      setUpdateLocationError(undefined)
    } else {
      setShowUpdateEnabled(false)
      setUpdateLocation(undefined)
      setUpdateLocationError(undefined)
    }
  }

  const onSaveToggleLocationEnabled = (notes: string) => {
    locationsClient
      .postToggleEnabled(updateLocation!.id, ToggleEnabledDTO.fromJS({
        enabled: !updateLocation!.enabled,
        notes: notes
      }))
      .then((resp: ApiResponse) => {
        setShowUpdateEnabled(false)
        setUpdateLocation(undefined)
        setUpdateLocationError(undefined)
        setActionResult(resp.responseCode === HttpStatusCode.OK 
          ? ApiResponse.fromJS({
              responseType: HttpResponseType.Success,
              messages: [
                `${updateLocation!.name} ${!updateLocation!.enabled ? 'Enabled' : 'Disabled'}`
              ]
            })
          : ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: resp.messages
            }))

        getLocations()
      })
      .catch((exception: ApiException) => {
        setUpdateLocationError(ApiResponse.fromJS({
          responseType: HttpResponseType.Error,
          messages: [
            `Error updating location ${updateLocation!.id}: ${getReasonPhrase(exception.status)}`
          ]
        }))
      })
  }

  useEffect(() => {
    getLocations()
  }, [getLocations])

  const [colDefs] = useState<ColDef[]>([
    { headerName: 'Name', flex: 1, filter: true, initialSort: 'asc', field: 'name' },
    { headerName: 'Key', flex: 1, filter: true, field: 'key' },
    { headerName: 'City', flex: 0, width: 150, filter: 'string', field: 'cityCode' },
    { 
      headerName: 'Enabled', 
      field: 'enabled',
      cellRenderer: "updateEnabledToggleRenderer", 
      width: 120,
      flex:0,
      resizable: false,
      cellRendererParams: {
        onToggle: toggleLocationEnabled
      }
    },
    { 
      field: '',
      width: 80,
      flex:0,
      sortable: false,
      resizable: false,
      cellRenderer: 'editLocationRenderer'
    }
  ])

  return (
    <WrappedPage breadcrumbs={[{ label: "Locations" }]}>
      <CCard>
        <SCardHeader
          title="Locations"
          actionButtons={actionButtons}
        ></SCardHeader>
        <CCardBody className="c-body-full">
          {!ready ? (
            <Loading color="primary" label="Loading Locations"></Loading>
          ) : null}
          {ready && actionResult !== undefined ? (
            <ApiResponseAlert data={actionResult}></ApiResponseAlert>
          ) : null}
          {ready && locations !== undefined ? (
            <SGrid
              frameworkComponents={{ "editLocationRenderer": EditLocationRenderer }}
              columnDefs={colDefs}
              data={locations}
            ></SGrid>
          ) : null}
        </CCardBody>
      </CCard>
      <ToggleEnabledDialog 
        title={updateLocation !== undefined ? `${updateLocation!.enabled ? "Disable" : "Enable"} ${updateLocation!.name}` : ""}
        onCancel={() => toggleLocationEnabled(undefined)} 
        onConfirm={onSaveToggleLocationEnabled} 
        show={showUpdateEnabled} 
        errorMessage={updateLocationError}
      ></ToggleEnabledDialog>
    </WrappedPage>
  );
}