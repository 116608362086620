import { FC } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import {
  ApplicationPaths,
  QueryParameterNames
} from "./ApiAuthorizationConstants";
import Loading from "../common/Loading";

type AuthorizeRouteProps = {
  ready: boolean;
  authenticated: boolean;
  Component: FC<any>;
  [x: string]: any;
};

function AuthorizeRoute({
  ready,
  authenticated,
  Component,
  ...rest
}: AuthorizeRouteProps) {
  const navigation = useHistory();
  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURI(navigation.location.pathname)}`;

  console.log("Ready: " + ready)
  console.log("Auth: " + authenticated)

  if (!ready) {
    return <Loading label="Loading..." color="primary"></Loading>;
  }

  if(!authenticated) {
    return <Redirect to={redirectUrl} />;
  }

  console.log(rest)

  return (
    <Route
      {...rest}
      render={props => <Component ready={ready} authenticated={authenticated} {...props} />}
    />
  );
}

export default AuthorizeRoute;
